import React from 'react'
import { Layout, Warpper } from '@raylink/common/components'
import styled from 'styled-components'
import { Media } from '@raylink/common/theme'
import TradeImg from '@raylink/main/src/images/allies/digitalychee-logo.png'

const Wrapper = styled(Warpper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  margin-bottom: 90px;

  ${Media.phone`
    margin-top: 0;
    padding-top: 1.44rem;
  `}
`

const Title = styled(Warpper)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  &.TitleEng {
    font-size: 30px;
  }

  ${Media.phone`
    padding-bottom: 0.3rem;
    font-size: 0.6rem;
    &.TitleEng {
      font-size: 0.46rem;
    }
  `}
`

const ContentWrap = styled(Warpper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  .TradeImgWrap {
    img {
      width: 700px;
    }
    ${Media.phone`
      img {
        width: 6rem;
      }
  `}
  }

  &.mobile-view {
    display: none;
  }

  ${Media.phone`
    padding-top: 0.4rem;
    margin-top: unset;
    width: 300px;
    &.pc-view {
      display: none;
    }
    &.mobile-view {
      display: block;
    }
  `}
`

const ContLine = styled(Warpper)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  span {
    font-weight: 600;
  }
  ${Media.phone`
    padding-bottom: 0.3rem;
  `}
`

const DigitalycheePage = () => {
  return (
    <Layout>
      <Wrapper>
        <Title>授权证明</Title>
        <ContentWrap>
          <ContLine>
            <span>大鱼互联科技（深圳）有限公司</span>
            授权
            <span>上海立七数码科技有限公司</span>
            为中国区官方合作伙伴，
          </ContLine>
          <ContLine>
            并享有本公司旗下正版软件
            <span>RayLink超级性能会员、全球性能会员</span>
            在中国区的销售资质。
          </ContLine>
          <ContLine>
            该合作伙伴旗下店铺域名或名称中均包含商标
            <span>数码荔枝</span>或
            <div className="shop">
              <span>DIGITALYCHEE</span>，
            </div>
          </ContLine>
          <ContLine>
            对应店铺网址为
            <span>digitalychee</span>.taobao.com /&nbsp;<span>lizhi.shop</span>
            。
          </ContLine>
          <ContLine>请勿在其他未经授权的网站购买我们的软件。</ContLine>
          <ContLine>我们不再为这样的顾客提供任何售后服务，</ContLine>
          <ContLine>包括：密钥找回、版本更新、技术支持。</ContLine>
          <div className="TradeImgWrap">
            <img src={TradeImg} alt="DIGITALYCHEE" loading="lazy" />
          </div>
        </ContentWrap>
        <Title className="TitleEng">AUTHORIZATION CERTIFICATE</Title>
        <ContentWrap className="pc-view">
          <ContLine>
            We, Dayu Internet Technology (Shenzhen) Co., Ltd. the developer of
            RayLink
          </ContLine>
          <ContLine>
            authorizes&nbsp;
            <span>DIGITALYCHEE TECHNOLOGY CO, LTD</span>
            &nbsp;as our official partner in China,
          </ContLine>
          <ContLine>
            and it owns the right to sell the copyrighted software of us on
            their stores:
          </ContLine>
          <ContLine>
            <span>digitalychee</span>.taobao.com /&nbsp;<span>lizhi.shop</span>
          </ContLine>
          <ContLine>
            Please {`don't`} purchase our product(s) from any other unauthorized
            websites.
          </ContLine>
          <ContLine>
            Customers who purchase from them are not allowed to enjoy the
            official customer support,
          </ContLine>
          <ContLine>
            including license retrieval, version update, tech assistance.
          </ContLine>
        </ContentWrap>
        <ContentWrap className="mobile-view">
          <ContLine>
            We, Dayu Internet Technology (Shenzhen) Co., Ltd. the developer of
            RayLink authorizes <span>DIGITALYCHEE TECHNOLOGY CO, LTD</span> as
            our official partner in China,
          </ContLine>
          <ContLine>
            and it owns the right to sell the copyrighted software of us on
            their stores: <span>digitalychee</span>.taobao.com /&nbsp;
            <span>lizhi.shop</span>
          </ContLine>
          <ContLine>
            Please {`don't`} purchase our product(s) from any other unauthorized
            websites.
          </ContLine>
          <ContLine>
            Customers who purchase from them are not allowed to enjoy the
            official customer support, including license retrieval, version
            update, tech assistance.
          </ContLine>
        </ContentWrap>
      </Wrapper>
    </Layout>
  )
}

export default DigitalycheePage
